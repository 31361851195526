import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";
import { ScrollToSection } from "../common/function/scroll-to";

export function Footer() {
  return (
    <footer className={style.footer}>
      <div style={{ maxWidth: "980px", flex: 1 }}>
        <Flex direction="row" align="center">
          <img
            alt=""
            src="img/pnr-logo.png"
            style={{
              width: "45px",
              objectFit: "contain",
            }}
          />
          <h1>Pinar Senadim</h1>
        </Flex>
        <div className="divider">
          <hr />
        </div>
        <Flex className={style.menu}>
          <Flex>
            <h3>Menu</h3>
            <Flex gap="12" className={style.navigation}>
              <span onClick={() => ScrollToSection("home")}>Anasayfa</span>
              <span onClick={() => ScrollToSection("about")}>Hakkında</span>
              <span onClick={() => ScrollToSection("service")}>Hizmetler</span>
              <span onClick={() => ScrollToSection("teraphy")}>
                Online Terapi
              </span>
              <span onClick={() => ScrollToSection("contact")}>Iletișim</span>
            </Flex>
          </Flex>
          <Flex>
            <h3>Hizmetlerimiz</h3>
            <Flex gap="12" className={style.navigation}>
              <span onClick={() => ScrollToSection("service")}>
                Bireysel Terapi
              </span>
              <span onClick={() => ScrollToSection("service")}>
                Aile Terapisi
              </span>
              <span onClick={() => ScrollToSection("service")}>
                Grup Terapi
              </span>
              <span onClick={() => ScrollToSection("service")}>
                Çocuk-Ergen Terapisi
              </span>
              <span onClick={() => ScrollToSection("service")}>
                Çift Terapisi
              </span>
              <span onClick={() => ScrollToSection("service")}>
                Bağımlılık Danışmanlığı
              </span>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </footer>
  );
}
