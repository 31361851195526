import style from "./style/style.module.css";
import { ServiceDrugTeraphy } from "./components/service-drug-teraphy";
import { useState } from "react";
import { ServiceItem } from "./components/service-item";

export interface ItemType {
  img: string;
  title: string;
  desc: string;
  price: string;
}
export function Service() {
  const [states] = useState([
    {
      title1: "Bireysel",
      title2: "Terapi",
      desc: " Terapist ile birebir yapılan bu terapi türünde; kişi düşünce, duygu ve davranışlarını keşfederek yaşamındaki zorluklarla başa çıkma becerilerini geliştirir.",
    },
    {
      title1: "Aile",
      title2: "Terapisi",
      desc: " Aile üyeleri arasındaki iletişim, ilişki ve uyum problemlerini çözmek amacıyla profesyonel rehberlik ve destek sunan bir terapi yöntemidir.",
    },
    {
      title1: "Grup",
      title2: "Terapi",
      desc: "Terapist rehberliğinde, benzer konularda destek ihtiyacı duyan bireylerin deneyimlerini paylaşmalarına olanak tanıyan bir psikoterapi yöntemidir.",
    },
    {
      title1: "Çocuk-Ergen",
      title2: "Terapisi",
      desc: "Çocuklar ve ergenler için özel olarak tasarlanmış terapi hizmetimiz, duygusal ve davranışsal zorlukları etkili bir şekilde çözerek, gelişimsel ihtiyaçlara uygun uzman terapist desteği sunar.",
    },
    {
      title1: "Çift",
      title2: "Terapisi",
      desc: "İlişkilerdeki sorunları ele almak, iletişimi güçlendirmek ve sağlıklı bir bağ oluşturmak için profesyonel destek sunan bir psikoterapi yöntemidir.",
    },
  ]);

  return (
    <section className={style.container} id="service">
      <div className={style.content}>
        <div style={{ textAlign: "start" }}>
          <h1>Danışmanlık ve Terapi Hizmetleri</h1>
          <span style={{ opacity: 0.5 }}>
            Kişisel ihtiyaçlarınızı karşılamak için geniş bir hizmet yelpazesi
            sunuyoruz
          </span>
        </div>
        <div className={style.items}>
          {states.map((item, i) => (
            <ServiceItem
              title1={item.title1}
              title2={item.title2}
              desc={item.desc}
            />
          ))}
          <ServiceDrugTeraphy />
        </div>
      </div>
    </section>
  );
}
