import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import style from "./style/style.module.css";
import { Flex } from "../common/flex/flex";
import emailjs from "emailjs-com";
import { useEffect, useRef, useState } from "react";
import { config } from "../../config";
import { Input } from "../common/input/input";
import { UseQueryIp } from "./hooks/use-query-ip";

export function Contact() {
  const [ip, setIp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const [email, setEmail] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    UseQueryIp().then((data) => {
      setIp(data.ip);
    });
  }, []);

  function phoneRegex(number: string) {
    if (number === undefined) return "";

    const phone = number.replace(/[^0-1-2-3-4-5-6-7-8-9/+]/g, "");
    return phone;
  }

  async function sendEmail() {
    setMessage(null);

    if (email.name === "" || email.name.length < 3) {
      setMessage("Lutfen isim alanini doldurunuz");
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.email)) {
      setMessage("Lutfen gecerli email adresi giriniz");
      return;
    }

    if (email.phone.length < 7 && email.phone.length > 13) {
      setMessage("Lutfen gecerli telefon numarasi giriniz");
      return;
    }

    setMessage(null);

    try {
      emailjs.init(config.email_key);
      setIsLoading(true);
      await emailjs.send(config.email_service, config.email_template, {
        from_email: email.email,
        from_ip: ip,
        from_name: email.name,
        message: email.message,
        from_phone: email.phone,
        from_ip_link: `https://www.ip2location.com/demo/${ip}`,
      });
      setMessage("Email gonderildi.");
      setEmail({ ...email, name: "", message: "", email: "", phone: "" });
    } catch (error) {
      console.error("Hata olustu.", error);
    }

    setIsLoading(false);
  }

  return (
    <section className={style.container} id="contact">
      <div className={style.content}>
        <div>
          <h1>İyileşme Yolculuğunuza Başlayın</h1>
          <span>
            Bugün özel bir uzmanla bağlantı kurun ve daha sağlıklı, daha tatmin
            edici bir hayata doğru ilk adımı atın.
          </span>
          <div className="divider">
            <hr />
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <img
              alt=""
              src="img/message.png"
              style={{
                width: "215px",
                margin: "0 auto",
                objectFit: "contain",
                filter: "grayscale(1)",
                transform: "rotate(10deg)",
              }}
            />
          </div>
        </div>
        <Flex>
          <Flex align="flex-start" gap="0">
            <h2 style={{ color: "#FFF" }}>Hazır mısınız ? </h2>
            <p style={{ color: "#FFF" }}>
              Terapistinizle ücretsiz 15 dakikalık ön görüşmenizi hemen
              planlayın.
            </p>
          </Flex>
          <div className={style.message}>
            <Input
              flex="1"
              title="Ad Soad"
              value={email.name}
              onChange={(e) => setEmail({ ...email, name: e.target.value })}
            />
            <Input
              flex="1"
              title="Telefon"
              value={email.phone}
              onChange={(e) =>
                setEmail({ ...email, phone: phoneRegex(e.target.value) })
              }
            />
            <Input
              flex="1"
              title="Email"
              value={email.email}
              onChange={(e) => setEmail({ ...email, email: e.target.value })}
            />
            <Input
              flex="1"
              title="Mesaj"
              value={email.message}
              onChange={(e) => setEmail({ ...email, message: e.target.value })}
            />
            {message && <span className={style.error}>{message}</span>}

            <div>
              <br />
              <button
                className={style.send_button}
                onClick={sendEmail}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
                <span>Iletisime Gec</span>
              </button>
            </div>
          </div>
        </Flex>
      </div>
    </section>
  );
}
