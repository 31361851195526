import {
  faCircleNodes,
  faFlaskVial,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Idea() {
  const ideas = [
    {
      icon: faFlaskVial,
      name: "Bilimsel Terapi Yöntemi",
      desc: "Online terapi, en güncel psikoterapi teknikleriyle ve bilimsel temellere dayalı olarak hizmet sunan modern bir terapi yöntemidir.",
    },
    {
      icon: faCircleNodes,
      name: "Erişim Kolaylığı",
      desc: "Mekan kısıtlaması olmadan, sadece bir cihaz ve internet bağlantısıyla istediğiniz her yerden terapistinize kolayca ulaşabilirsiniz.",
    },
    {
      icon: faShieldHalved,
      name: "Gizlilik",
      desc: "Online terapi, güvenli bir ortamda gerçekleştirilir ve kişisel bilgileriniz titizlikle korunur.",
    },
  ];

  return (
    <>
      <hr />
      <section className={style.container} id="teraphy">
        <div className={style.grid}>
          <div>
            <Flex align="flex-start" gap="8">
              <h2 style={{ fontSize: "32px", textAlign: "start" }}>
                Zaman ve Mekâna Takılmayın
              </h2>
              <span style={{ textAlign: "start" }}>
                Günümüzün hızlı tempolu dünyasında, zaman ve mekân sınırlamaları
                sizi zorlamasın.
              </span>
            </Flex>
            <div className="divider">
              <hr />
            </div>
            <Flex gap="32">
              {ideas.map((item, i) => (
                <div key={i} className={style.item}>
                  <Flex className={style.items_icon}>
                    <FontAwesomeIcon icon={item.icon} />
                  </Flex>
                  <Flex
                    align="flex-start"
                    style={{ textAlign: "start" }}
                    gap="6"
                  >
                    <h3>{item.name}</h3>
                    <label>{item.desc}</label>
                  </Flex>
                </div>
              ))}
            </Flex>
          </div>
          <div className={style.video_container}>
            <video
              loop
              muted
              autoPlay
              src="/img/video_2.mp4"
              className={style.video}
            ></video>
          </div>
        </div>
      </section>
    </>
  );
}
