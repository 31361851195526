import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";

export function CopyRight() {
  return (
    <div className={style.content}>
      <Flex>
        <label>© Copyright All Rights Reserved By Pinar Senadim 2024</label>
      </Flex>
      <Flex direction="row" gap="12" align="center">
        <label>Design by</label>
        <img alt="" width={40} height={40} src="/img/island-software.png" />
        <strong>Island Software</strong>
      </Flex>
    </div>
  );
}
