import style from "./style/style.module.css";
import { Flex } from "../common/flex/flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { ScrollToSection } from "../common/function/scroll-to";
import { useRef } from "react";

export function Welcome() {
  const video = useRef<HTMLVideoElement | null>(null);

  return (
    <section className={style.container} id="home">
      <div className={style.content_bg} />
      <div className={style.text}>
        <Flex gap="18" align="flex-start">
          <Flex
            gap="6"
            delay={18}
            direction="row"
            align="center"
            style={{
              padding: "12px",
              borderRadius: "18px",
              background: "#465455",
            }}
            animation="data-anim-translate-up"
          >
            <span
              style={{
                fontSize: "12px",
                color: "#7dbabf",
                fontWeight: "700",
              }}
            >
              Uzman Klinik Psikolog
            </span>
            <span style={{ fontWeight: "700", color: "#EEE" }}>
              Pınar Şenadım
            </span>
          </Flex>

          <Flex gap="0">
            <h1
              style={{
                color: "#FFF",
                width: "450px",
                fontSize: "42px",
                lineHeight: "50px",
              }}
            >
              Kendi ic dengeni ve de uyumu bul ic dengeni ve uyumu bul
            </h1>
            <h4 style={{ color: "#EEE", fontWeight: 500, fontSize: "18px" }}>
              Kişisel ve ailevi sorunların çözümü için profesyonel psikolojik
              destek
            </h4>
          </Flex>

          <Flex
            delay={21}
            direction="row"
            style={{ marginTop: "12px" }}
            animation="data-anim-translate-up"
          >
            <button
              onClick={() => ScrollToSection("contact")}
              className={style.welcome_button}
            >
              <span>Iletişime Geç</span>
            </button>
            <button className={style.video_button}>
              <FontAwesomeIcon icon={faVideo} />
              <span>Mesajimi izle</span>
            </button>
          </Flex>
        </Flex>
      </div>
      {/* <img
        alt="pinar senadim"
        src="/img/my.jpeg"
        style={{
          inset: 0,
          width: "100%",
          height: "110%",
          objectFit: "cover",
          position: "absolute",
          filter: "grayscale(1)",
        }}
      /> */}

      <Flex className={style.video_container}>
        <video
          ref={video}
          loop
          muted
          autoPlay
          playsInline
          src="/img/main3.mp4"
          className={style.video}
        />
      </Flex>
    </section>
  );
}
