import { useState } from "react";
import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";

export function About() {
  const [isMore, setIsMore] = useState(false);
  return (
    <section className={style.container} id="about">
      <div className={style.content}>
        <div className={style.text_content}>
          <Flex gap="0" padding="0 0 32px 0">
            <h3>Uzman Klinik Psikolog</h3>
            <h1>Pınar Şenadım</h1>
          </Flex>
          <p className={style.text}>
            Lisans eğitimini <strong>Çağ Üniversitesi</strong> Psikoloji
            Bölümü’nde, İngilizce olarak tamamladı. Eğitim sürecinde, çeşitli
            hastanelerin psikiyatri bölümlerinde gönüllü olarak hasta
            gözlemlerinde bulundu ve klinik deneyim kazandı. Yüksek lisans
            eğitimini ise <strong>Çukurova Üniversitesi</strong>'nde "Zorunlu
            Göç Eden Bireylerin Bağımlılık Profili ile Travma Sonrası Stres
            Bozukluğunun İlişkisi" konulu tez çalışmasıyla tamamladı.
          </p>
          <br />

          {isMore && (
            <div style={{ marginBottom: "16px" }}>
              <p className={style.text}>
                Profesyonel kariyerine,{" "}
                <strong>
                  Birleşmiş Milletler Uluslararası Göç Örgütü (IOM)
                </strong>{" "}
                bünyesinde psikolog olarak başladı. Daha sonra, bağımlılık
                alanında hizmet veren{" "}
                <strong>Yeşilay Danışmanlık Merkezleri</strong>'nde Uzman
                Psikolog olarak çalıştı. Ulusal ve uluslararası düzeyde birçok
                kongrede eğitimler verdi.{" "}
              </p>
              <br />
              <p className={style.text}>
                <strong>Pınar Şenadım</strong>, Travma Sonrası Stres Bozukluğu,
                Anksiyete, Depresyon, Öfke Kontrol Bozuklukları, Yeme
                Bozuklukları, Obsesif Kompulsif Bozukluk ve Bağımlılık gibi
                alanlarda uzmandır. Klinik çalışmalarında birey odaklı yaklaşımı
                benimseyerek; Bilişsel Davranışçı Terapi, Kabul ve Kararlılık
                Terapisi ve Mindfulness gibi modern terapi yöntemleriyle
                çalışmaktadır.
              </p>
            </div>
          )}
          <a
            href="#hakkimda"
            style={{ marginLeft: "14px" }}
            onClick={() => setIsMore(!isMore)}
          >
            {!isMore ? "Devamini gôr" : "Gizle"}
          </a>
        </div>
        <div className={style.img_container}>
          {/* <img src="/img/about.png" alt="" className={style.img} /> */}
        </div>
      </div>
    </section>
  );
}
